.root {}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.imageSection {
  position: relative;

  &>div {
    & img {
      border-radius: 0;
    }

    &:nth-child(2) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: 0;

      /* @media(max-width:560px) {
        top: 100%;
      } */

      & h3 {
        font-size: 27px;
        font-weight: 600;
        line-height: 32px;
        font-family: "Josefin Sans";
        letter-spacing: 1px;
        text-align: center;
        padding: 0;
        color: #FCF8F2;
      }

      & p {
        & a {
          font-size: 27px;
          font-weight: 600;
          line-height: 32px;
          font-family: "Josefin Sans";
          letter-spacing: 1px;
          text-align: center;
          padding: 0;
          color: #FCF8F2;
          display: flex;
          align-items: center;
          justify-content: center;

          @media(max-width:560px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.searchWrapper {
  display: flex;
  position: relative;

  @media(max-width:460px) {
    flex-direction: column;
    gap: 20px;
  }

  & input {
    width: 70%;
    min-height: 59px;
    padding-left: 50px;
    border-radius: 10px;

    @media(max-width:460px) {
      width: 100%;
    }

    &::placeholder {
      font-family: "Inter";
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3047058880329132px;
      text-align: left;
    }
  }

  & svg {
    position: absolute;
    left: 14px;
    top: 18px;
    fill: none;
  }

  & .shopButton {
    width: 33%;
    min-height: 59px;
    background-color: #A28930;
    font-family: "Josefin Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 2px;
    text-align: center;
    color: #FCF8F2;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #A28930;
    margin-left: -15px;
    cursor: pointer;

    @media(max-width:460px) {
      width: 100%;
      margin-left: 0;
    }
  }
}

.categoryStore {
  position: absolute;
  right: 0;
  bottom: 0;

  & .shopWrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 11px 26px;
    background: #4A2A188C;
    border-top-left-radius: 25px;

    @media(max-width:560px) {
      padding: 7px 12px;
    }

    & .shopImg {
      & img {
        height: 46px;
        width: 46px;
        border: 1px solid #FCF8F2;
        border-radius: 100%;

        @media (max-width:560px) {
          height: 36px;
          width: 36px;
        }
      }
    }

    & .shopName {
      font-family: "Josefin Sans";
      font-size: 18px;
      font-weight: 500;
      line-height: 25.31px;
      text-align: left;
      color: #FCF8F2;
      text-decoration: underline;

      @media(max-width:560px) {
        font-size: 14px;
      }
    }
  }
}