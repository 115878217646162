@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.heroSection {
  background-color: #FCF8F2;

  &>div {
    &>div {
      max-width: 1260px;
      align-items: center;

      @media(max-width:1024px) {
        grid-template-columns: repeat(1, 1fr);
      }

      &>div {
        &:nth-child(1) {
          &>div {
            & h3 {
              font-size: 36px;
              font-weight: 400;
              line-height: 56.25px;
              text-align: left;
              color: #5B4C23;

              @media(max-width:1170px) {
                font-size: 30px;
              }

              @media(max-width:560px) {
                line-height: 40px;
                font-size: 26px;
              }
            }

            & p {
              font-family: "Karla", sans-serif;
              font-size: 18px;
              font-weight: 400;
              line-height: 25.31px;
              text-align: left;
              color: #5B4C23;
              /* max-width: 530px; */
              padding: 20px 0;

              @media(max-width:560px) {
                padding: 10px 0;
                font-size: 16px;
              }

              &:last-child {
                display: flex;
                gap: 30px;
                align-items: center;
                max-width: 100%;

                @media(max-width:460px) {
                  flex-direction: column;
                  align-items: flex-start;
                }

                & a {
                  &:first-child {
                    background-color: #A28930;
                    font-family: "Josefin Sans";
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 2px;
                    text-align: center;
                    color: #FCF8F2;
                    display: flex;
                    text-transform: uppercase;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    padding: 21px 31px 18px;

                    @media(max-width:560px) {
                      border: 2px solid #A28930;
                      font-size: 14px;
                      padding: 15px 25px 15px;
                      width: 100%;
                    }
                  }

                  &:last-child {
                    background-color: #f2e9e0;
                    font-family: "Josefin Sans";
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 2px;
                    text-align: center;
                    color: #A28930;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    border: 2px solid #A28930;
                    padding: 20px 31px 15px;
                    text-transform: uppercase;

                    @media(max-width:560px) {
                      font-size: 14px;
                      padding: 15px 25px 15px;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }

        }

        &:nth-child(2) {
          background-color: #5B4C23;

          &>div {
            &>div {
              height: 419px;

              @media(max-width:768px) {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.collectionSection {
  background-color: #FCF8F2;

  &>div {
    & header {
      max-width: 1260px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media(max-width:675px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      & h2 {
        font-size: 40px;
        font-weight: 400;
        line-height: 56.25px;
        text-align: left;
        color: #5B4C23;
        text-align: left;

        @media (max-width:1024px) {
          font-size: 30px;
        }

        @media(max-width:520px) {
          font-size: 24px;
        }
      }

      & a {
        font-family: "Josefin Sans";
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 1px;
        text-align: right;
        color: #5B4C23;
        background: transparent;
        text-transform: uppercase;
        box-shadow: none;
        justify-content: flex-end;
        display: flex;
        position: relative;
        margin: 0;
        padding: 0 74px 10px 0;

        @media (max-width:1024px) {
          font-size: 13px;
          font-weight: 500;
          padding: 0 0 0 0;
        }

        @media(max-width:520px) {
          font-size: 12px;
        }

        &::after {
          content: "";
          width: 400px;
          background-color: #5B4C23;
          height: 1px;
          bottom: 0;
          position: absolute;
          right: -66px;

          @media(max-width:1360px) {
            right: 0px;
            width: 330px;
          }

          @media(max-width:1024px) {
            width: 200px;
          }
        }

        &:hover {
          background-color: transparent;
        }
      }
    }

    &>div {
      max-width: 1260px;
      gap: 8px;
      padding-bottom: 100px;

      @media (max-width:1170px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width:768px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 18px;
      }

      /* @media (max-width:460px) {
        grid-template-columns: repeat(1, 1fr);
      } */

      &>div>div {
        /* @media(max-width:560px) {
          height: 250px;
        } */
      }
    }
  }
}

.storySection {
  position: relative;
  height: 505px;

  @media(max-width:440px) {
    height: 600px;
  }

  &>div {
    & h3 {
      font-size: 40px;
      font-weight: 400;
      line-height: 56.25px;
      text-align: center;
      color: #ffffff;
      /* padding-top: 60px; */

      @media(max-width:560px) {
        line-height: 40px;
        font-size: 26px;
        padding-top: 0;
      }
    }

    & p {
      font-family: "Karla", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 25.31px;
      text-align: center;
      color: #ffffff;
      /* padding-top: 40px; */
      max-width: 100%;

      @media(max-width:560px) {
        line-height: 20px;
        font-size: 16px;
        padding-top: 0;
      }
    }
  }
}

.producerSection {
  background-color: #FCF8F2;

  &>div {
    @media(min-width: 768px) {
      padding-top: 130px;
    }

    &>div {
      max-width: 1260px;
      align-items: center;

      &>div {
        &:nth-child(1) {
          & img {
            border-radius: 0;
          }
        }

        &:nth-child(2) {
          &>div {
            @media(min-width:1024px) {
              padding-left: 85px;
            }
          }

          & h3 {
            font-size: 27px;
            font-weight: 400;
            color: #5B4C23;
          }

          & p {
            font-family: "Karla", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 25.31px;
            color: #5B4C23;

          }

          & a {
            font-family: "Josefin Sans";
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 2px;
            padding: 16px 28px;
            text-align: center;
            border: 2px solid #A28930;
            border-radius: 10px;
            background-color: transparent;
            box-shadow: none;
            color: #A28930;

            @media(max-width:560px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.consumerSection {
  background-color: #FCF8F2;
  display: block;

  &>div {
    @media(min-width:768px) {
      padding-top: 0;
    }

    &>div {
      max-width: 1260px;
      align-items: center;

      &>div {
        &:nth-child(2) {
          & img {
            border-radius: 0;
          }
        }

        &:nth-child(1) {
          @media(max-width:768px) {
            order: 2;
          }

          &>div {
            @media(min-width:1024px) {
              padding-right: 85px;
            }
          }

          & h3 {
            font-size: 27px;
            font-weight: 400;
            color: #5B4C23;
          }

          & p {
            font-family: "Karla", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 25.31px;
            color: #5B4C23;
          }

          & a {
            font-family: "Josefin Sans";
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 2px;
            padding: 16px 28px;
            text-align: center;
            border: 2px solid #A28930;
            border-radius: 10px;
            box-shadow: none;
            color: #ffffff;

            @media(max-width:560px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.followSection {
  max-width: 1260px;
  margin: 0 auto;
  padding: 40px 32px;

  & .followWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media(max-width:768px) {
      grid-template-columns: 1fr;
    }

    & .socialSection {
      & .socialHeading {
        font-size: 25px;
        font-weight: 400;
        line-height: 35.16px;
        text-align: left;
        color: #5B4C23;
      }

      & .socialImages {
        display: flex;
        gap: 20px;
        margin-top: 26px;
      }
    }

    & .emailSection {
      & .emailHeading {
        font-size: 40px;
        font-weight: 400;
        line-height: 56.25px;
        text-align: left;
        color: #5B4C23;
      }

      & .emailSubHeading {
        font-family: "Karla", sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 25.31px;
        text-align: left;
        color: #5B4C23;
        padding-top: 15px;
      }

      & .emailInput {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-top: 50px;

        @media(max-width:1024px) {
          flex-direction: column;
          gap: 30px;
          align-items: flex-start;
        }

        & input {
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #5B4C23;
          border-radius: 0;
          width: 300px;

          @media(min-width:1224px) {
            width: 450px;
          }

          &::placeholder {
            font-family: "Josefin Sans";
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 2px;
            text-align: left;
            color: #5B4C23;
          }
        }

        & button {
          border: none;
          font-family: "Josefin Sans";
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 2px;
          text-align: left;
          color: #5B4C23;
        }
      }
    }
  }
}

.footerSection {

  &>div {
    padding: 0;
  }
}

.pepiHeroSection {
  background-color: #f2e9e0;

  &>div {
    &>div {
      max-width: 1260px;
      display: flex;
      gap: 60px;

      @media(max-width:950px) {
        flex-direction: column-reverse;
        gap: 20px;
      }

      &>div {
        &:first-child {
          width: 42%;

          @media(max-width:950px) {
            width: 100%;
          }

          &>div {
            & p {
              &:first-child {
                color: #5b4c23;
                font-size: 33px;
                font-weight: 400;
                line-height: 56.25px;
                text-align: left;

                @media(max-width:520px) {
                  font-size: 26px;
                  line-height: 44.25px;
                }

                & strong {
                  font-weight: 700;
                }
              }

              &:nth-child(2) {
                font-family: "Karla", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 25.31px;
                text-align: left;
                color: #5b4c23;
                max-width: 75%;
                margin-top: 10px;

                @media(max-width:520px) {
                  max-width: 100%;
                }
              }
            }
          }
        }

        &:last-child {
          width: 58%;
          height: 100%;
          position: relative;

          @media(max-width:950px) {
            width: 100%;
          }

          & a {
            position: absolute;
            right: 0;
            bottom: 4px;
            padding: 14px 32px;
            background: #4A2A188C;
            border-top-left-radius: 25px;
            box-shadow: none;
            text-decoration: underline;
            font-family: 'Josefin Sans';
            font-size: 18px;
            font-weight: 500;
            line-height: 25.31px;

            @media(max-width:1440px) {
              bottom: 20px;
            }

            @media(max-width:560px) {
              padding: 7px 14px;
              font-size: 14px;
            }
          }

          &>div {
            &>div {
              & img {
                border-radius: 0;
                position: relative;

                @media(min-width:1440px) {
                  height: 382px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.itemFlexSection {
  &>div {
    & header {
      justify-content: flex-start;
      max-width: 1260px;
      padding: 0;

      & h2 {
        text-align: left;
        font-size: 40px;
        font-weight: 400;
        line-height: 56.25px;
        text-align: left;
        color: #5B4C23;

        @media(max-width:768px) {
          padding: 0 24px;
        }
      }
    }
  }
}

.itemSection {
  max-width: 1260px;
  margin: 0 auto;

  @media(max-width:1360px) {
    padding: 0 34px;
  }

  @media(max-width:1024px) {
    padding: 0 24px;
  }

  .topButtonWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    @media(max-width:1320px) {
      flex-direction: column;
      gap: 20px;
    }

    @media(max-width:560px) {
      align-items: center;
    }

    & .listingButtons {
      display: flex;
      gap: 15px;
      align-items: center;
      /* flex-wrap: wrap; */
      overflow-x: auto;
      max-width: 800px;
      padding-bottom: 5px;
      cursor: pointer;

      /* width */
      &::-webkit-scrollbar {
        width: 10px;
        height: 6px;
        padding-top: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #5B4C23;
        border-radius: 4px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      @media(max-width:850px) {
        max-width: 600px;
        padding-bottom: 5px;
      }

      @media(max-width:650px) {
        max-width: 400px;
        padding-bottom: 5px;
      }

      @media(max-width:450px) {
        max-width: 300px;
        padding-bottom: 5px;
      }

      @media(max-width:600px) {
        overflow-x: auto;
        width: 100%;
      }

      & .activeButton {
        font-family: "Karla", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.20000000298023224px;
        text-align: center;
        background-color: #5B4C23;
        border: 1px solid #F2E9E0;
        color: #F2E9E0;
        padding: 16px 20px;
        border-radius: 4px;
        /* min-width: 190px; */
        cursor: pointer;

        @media(max-width:700px) {
          min-width: 150px;
          padding: 12px 20px;
        }
      }

      & .inactiveButton {
        font-family: "Karla1", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.20000000298023224px;
        text-align: center;
        background-color: #F2E9E0;
        border: 1px solid #5B4C23;
        color: #5B4C23;
        padding: 16px 20px;
        border-radius: 4px;
        /* min-width: 190px; */
        cursor: pointer;

        @media(max-width:700px) {
          min-width: 150px;
          padding: 12px 20px;
        }
      }
    }

    & .seeMoreButton {
      font-family: "Josefin Sans";
      font-size: 18px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 1px;
      text-align: left;
      text-transform: uppercase;
      position: relative;
      color: #5B4C23;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      & span {
        font-weight: 700;
      }

      @media(max-width:1320px) {
        margin-left: auto;
        border-bottom: 1px solid #5b4c23;
        width: fit-content;
      }

      @media(max-width:560px) {
        font-size: 14px;
      }

      &::after {
        background-color: #5b4c23;
        bottom: 0;
        content: "";
        height: 1px;
        position: absolute;
        width: 100%;
        left: 0;

        /* @media(max-width:1420px) {
          width: 300px;
        } */

        @media(max-width:1320px) {
          display: none;
        }
      }
    }
  }

  & .itemList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 48px;
    margin-top: 70px;

    @media(max-width:1370px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width:900px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 28px;
    }

    @media (max-width:900px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 28px;
    }

    @media (max-width:460px) {
      grid-template-columns: repeat(1, 1fr);
    }

    & .item {
      & img {
        width: 100%;
        height: 282px;
        object-fit: cover;
        border-radius: 4px;
        cursor: pointer;
      }

      & .noImageWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--colorGrey200);
        width: 100%;
        height: 282px;
        border-radius: 4px;

        & svg {
          stroke: #4A4A4A;
        }
      }

      & .itemPrice {
        font-family: "Josefin Sans";
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.3047058880329132px;
        text-align: left;
        color: #A28930;
        margin: 8px 0 0 0;
      }

      & .itemName {
        font-family: "Karla", sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color: #4A4A4A;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      & .storeName {
        font-family: "Karla", sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.20000000298023224px;
        text-align: left;
        color: #4A4A4A;
      }
    }
  }

  & .seeAllLisitng {
    width: 253px;
    margin: 36px auto;
    background-color: #A28930;
    font-family: "Josefin Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 2px;
    text-align: center;
    color: #FCF8F2;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #A28930;
    padding: 21px 0;

    @media(max-width:560px) {
      width: 100%;
      padding: 18px 0;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  & .itemListNoData {
    /* color: red; */
    color: #5B4C23;
    text-align: center;
    margin: 20px;
  }

}

.empowerSection {
  background-color: #FCF8F2;

  &>div {
    @media(min-width: 768px) {
      padding-top: 130px;
    }

    &>div {
      max-width: 1260px;
      align-items: center;

      &>div {
        &:nth-child(1) {
          & img {
            border-radius: 0;
          }
        }

        &:nth-child(2) {
          &>div {
            @media(min-width:1024px) {
              padding-left: 85px;
            }
          }

          & h3 {
            font-size: 27px;
            font-weight: 400;
            color: #5B4C23;
          }

          & p {
            font-family: "Karla", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 25.31px;
            color: #5B4C23;
          }
        }
      }
    }
  }
}

.grid {
  & :global(.ais-Hits) {
    /* width: 100%; */
    /* max-width: 1440px; */

    & :global(.ais-Hits-list) {
      display: flex;
      width: 100%;
      gap: 27px;
      flex-wrap: wrap;
      padding: 0;
      /* grid-template-columns: repeat(4, 1fr); */
      max-width: 100%;
      width: 100%;


      /* @media (max-width: 1200px) {
              grid-template-columns: repeat(3, 1fr);
          }

          @media (max-width: 900px) {
              grid-template-columns: repeat(2, 1fr);
          }

          @media (max-width: 580px) {
              grid-template-columns: repeat(1, 1fr);
          } */
    }

        & :global(.ais-Hits-item) {
          list-style-type: none;
          width: calc(23% - 12px);
    
          @media(max-width:1360px) {
            width: calc(32% - 12px);
          }
    
          @media(max-width:1024px) {
            width: calc(49% - 12px);
          }

      &>div {
        & a {
          &:hover {
            text-decoration: none;
          }

          &>div {
            & img {
              /* width: 100%;
                      height: 100%; */
            }
          }
        }
      }
    }
  }
}