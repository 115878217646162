@import '../../../../styles/customMediaQueries.css';

.root {
  border-top: 1px solid var(--colorGrey100);
}

.footer {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 24px 32px;
}

.footer ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-top: 1px;
}

.linkList {
  list-style-type: none;
  padding-left: 12px;
}

.content {
  display: grid;
  justify-content: center;
  align-content: end;
  grid-auto-rows: auto;
  grid-gap: 32px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'logo'
    'details'
    'grid'
    'links';
}

.grid {
  grid-area: grid;
  display: grid;
  justify-content: center;
  align-content: start;
  grid-auto-rows: auto;
  grid-gap: 32px;
}

.gridCol1,
.gridCol2,
.gridCol3,
.gridCol4 {
  grid-template-columns: 1fr;
}

.detailsInfo {
  grid-area: links;
}

.slogan {
  font-size: 16px;
  line-height: 24px;
}

.sloganMobile {
  grid-area: details;
  padding-bottom: 24px;

  @media (--viewportLarge) {
    display: none;
  }
}

.sloganDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.logo {
  grid-area: logo;
}

.copyright {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorGrey500);
  margin-top: 24px;
}

.icons {
  margin-top: 24px;

  @media (--viewportLarge) {
    margin-top: 0px;
  }
}

.icon {
  font-size: 28px;
  margin: 15px 15px 0px 0px;
  padding: 10px 15px;
}

.logoLink {
  display: inline-block;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}

.logoWrapper {
  max-width: 370px;
}

.logoImage {
  height: auto;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position: center;

  @media (--viewportLarge) {
    max-width: min(100%, 298px);
  }
}

@media (min-width: 350px) {

  .gridCol2,
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 600px) {

  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--viewportMedium) {
  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (--viewportLarge) {
  .detailsInfo {
    grid-area: details;
  }

  .contentCol1 {
    /* 1/6 of space is for grid, and grid uses 1 column internally */
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }

  .contentCol2 {
    /* 2/6 of space is for grid, and grid splits it to 2 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }

  .contentCol3 {
    /* 3/6 of space is for grid, and grid splits it to 3 columns */
    grid-template-columns: 2fr 1fr 1fr 2fr;
    grid-template-areas:
      'logo . . .'
      'details . grid grid'
      'details . grid grid'
      'details . grid grid';
  }

  .contentCol4 {
    /* 4/6 of space is for grid, and grid splits it to 4 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details grid grid'
      'details grid grid'
      'details grid grid';
  }

  .gridCol1 {
    grid-template-columns: 1fr;
  }

  .gridCol2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridCol3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.footerSection {
  background-color: #5B4C23;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  padding: 40px;

  @media(min-width:1200px) {
    padding: 100px;
  }

  @media(max-width:1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media(max-width:768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
  }

  @media(max-width:460px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & ul {
    & li {
      font-family: "Josefin Sans";
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.3047058880329132px;
      text-align: left;
      color: #FCF8F2;

      & a {
        font-family: "Josefin Sans";
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.3047058880329132px;
        text-align: left;
        color: #FCF8F2;
      }

      & input {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #FCF8F2;
        border-radius: 0;
        position: relative;
        color: #FCF8F2;
        padding-right: 38px;

        &::placeholder {
          font-family: "Josefin Sans";
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 2px;
          text-align: left;
          color: #FCF8F2;
        }
      }

    }

    & .listHeading {
      font-size: 16px;
      padding: 22px 0;
      text-transform: uppercase;
    }
    & .emailHeading {
      font-size: 16px;
      padding: 22px 0 0 0;
      text-transform: uppercase;
    }

    & .socialSection {
      display: flex;
      gap: 15px;
      align-items: center;
      margin-top: 30px;
    }

    & .copyrightText {
      font-family: "Karla", sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.1846153885126114px;
      text-align: left;
      padding-top: 50px;
    }

    & .emailInput {
      position: relative;

      &>div {
        position: absolute;
        top: 12px;
        right: 12px;

        @media(max-width:768px) {
          top: 0;
        }
      }
    }
  }
  & .socialLinks {
    display: flex;
    gap: 14px;
    margin-left: 24px;

    @media(max-width:500px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }

  & .footerLogo {
    & img {
      @media(max-width:500px) {
        width: 120px;
      }
    }
  }
}
.footerEmail {
&>div>div>div {
  &>form {
padding: 0 !important;
  }
}
& :global([data-ff-el="root"].ff-66a03ee9fc89cda0eb4a0d40 .fd-form-control:not(:placeholder-shown)+.fd-form-label) {
}
& :global([data-ff-el="root"].ff-66a03ee9fc89cda0eb4a0d40 .ff-66a03ee9fc89cda0eb4a0d40__label) {
  color: #FCF8F2;
  font-family: "Karla",sans-serif;
  font-size: 16px;
}
& :global([data-ff-el="root"].ff-66a03ee9fc89cda0eb4a0d40 .ff-66a03ee9fc89cda0eb4a0d40__control) {
  color: #FCF8F2;
  font-family: "Karla",sans-serif;
  font-size: 16px;
  border-bottom: 1px solid #FCF8F2;
}
& :global([data-ff-el="root"].ff-66a03ee9fc89cda0eb4a0d40 .ff-66a03ee9fc89cda0eb4a0d40__preference-title) {
  color: #FCF8F2;
  font-family: "Karla",sans-serif;
}
& :global([data-ff-el="root"].ff-66a03ee9fc89cda0eb4a0d40 .ff-66a03ee9fc89cda0eb4a0d40__form-check .fd-form-check__label) {
  color: #FCF8F2;
  font-family: "Karla",sans-serif;
}
& :global([data-ff-el="root"].ff-66a03ee9fc89cda0eb4a0d40 .ff-66a03ee9fc89cda0eb4a0d40__button) {
  color: #FCF8F2;
  border: 1px solid #FCF8F2;
  font-family: "Karla",sans-serif;
}
:global([data-ff-el="root"].ff-66a03ee9fc89cda0eb4a0d40 .ff-66a03ee9fc89cda0eb4a0d40__form-check .fd-form-check__checkmark) {
  background-color: #FCF8F2;
}
}
