.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: grid;
  display: block !important;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

& :global(#herosection) {
  min-height: 450px !important;

  & h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 56.25px;
    text-align: center;
    color: #fff;

    @media(max-width:520px) {
      font-size: 30px;
      line-height: normal;
    }
  }

  & p {
    font-family: "Karla", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 25.31px;
    text-align: center;
    color: #fff;
    max-width: 100%;
  }

  & a {
    background: #A4C3E6;
    font-family: "Josefin Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 2px;
    text-align: center;
    padding: 18px;
    min-width: 220px;
    margin: 30px auto;
  }
}

:global(#valueprop) {

  &>div {
    @media(min-width:768px) {
      padding: 105px 0;
    }

    &>div {
      grid-template-columns: 50% 45%;
      max-width: 1260px;

      @media(min-width:1340px) {
        gap: 100px;
      }

      @media(max-width:768px) {
        grid-template-columns: 100%;
      }

      &>div {
        &:nth-child(1) {
          &>div {
            height: 100%;
          }

          & img {
            border-radius: 0;
          }
        }

        &:nth-child(2) {
          & h3 {
            font-size: 40px;
            font-weight: 400;
            line-height: 56.25px;
            text-align: left;
            color: #5B4C23;

            @media(max-width:460px) {
              font-size: 30px;
              line-height: normal;
            }
          }

          & p {
            font-family: "Karla", sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 25.31px;
            text-align: left;
            color: #5B4C23;
          }

          & a {
            font-family: "Josefin Sans";
            font-size: 14px;
            font-weight: 600;
            line-height: 14px;
            letter-spacing: -0.2666666805744171px;
            text-align: center;
            color: #ffffff;
            background-color: #A58930;
            padding: 13px 22px;
            border-radius: 10px;

            @media(max-width:460px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

:global(#looking-for) {

  &>div {
    @media(min-width:768px) {
      padding-top: 0;
    }

    &>div {
      grid-template-columns: 45% 50%;
      max-width: 1260px;

      @media(min-width:1340px) {
        gap: 100px;
      }

      @media(max-width:768px) {
        grid-template-columns: 100%;
      }

      &>div {
        &:nth-child(2) {
          &>div {
            height: 100%;
          }

          & img {
            border-radius: 0;
          }
        }

        &:nth-child(1) {
          @media(max-width:768px) {
            order: 2;
          }

          & h3 {
            font-size: 40px;
            font-weight: 400;
            line-height: 56.25px;
            text-align: left;
            color: #5B4C23;
          }

          & p {
            font-family: "Karla", sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 25.31px;
            text-align: left;
            color: #5B4C23;

            &:nth-child(3) {
              display: flex;
              gap: 55px;
              align-items: center;

              @media(max-width:460px) {
                flex-direction: column;
                gap: 20px;
              }

              &>a {
                font-family: "Josefin Sans";
                font-size: 14px;
                font-weight: 600;
                line-height: 14px;
                letter-spacing: -0.2666666805744171px;
                text-align: center;
                color: #ffffff;
                background-color: #A58930;
                padding: 13px 22px;
                border-radius: 10px;
                width: 150px;

                @media(max-width:460px) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

:global(#our-member) {

  &>div {
    @media(min-width: 1024px) {
      padding: 125px 0;
    }

    & header {
      & h2 {
        font-size: 40px;
        font-weight: 400;
        line-height: 56.25px;
        text-align: left;
        color: #5B4C23;

        @media(max-width:520px) {
          font-size: 30px;
        }
      }
    }

    &>div {
      max-width: 1260px;
      gap: 50px;
      padding-top: 74px;

      @media(max-width:1024px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

:global(#seller-name) {
  display: flex;
  gap: 40px;
  align-items: center;

  @media(max-width:580px) {
    flex-direction: column;
  }

  &>div {
    height: 100%;

    &:nth-child(1) {
      & img {
        border-radius: 0;
      }
    }

    &:nth-child(2) {
      margin-top: 0;

      & h3 {
        font-size: 26px;
        font-weight: 400;
        line-height: none;
        text-align: left;
        color: #5B4C23;
        padding: 0;
        margin: 0;
      }

      & p {
        &:nth-child(2) {
          padding: 0;
          margin: 0;
          line-height: none;
          font-size: 16px;
          color: #5B4C23;
          font-weight: 700;
          line-height: 22.5px;
          text-align: left;
          text-decoration: underline;
        }

        &:nth-child(3) {
          font-family: "Karla", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 25.31px;
          text-align: left;
          color: #5B4C23;
        }

        & a {
          border: 2px solid #A28930;
          font-family: "Josefin Sans";
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 2px;
          text-align: center;
          color: #A28930;
          padding: 15px;
          border-radius: 10px;
        }
      }
    }
  }
}